import Carousel from 'stimulus-carousel'

export default class extends Carousel {
  connect() {
    super.connect()

    // The swiper instance.
    this.swiper

    // Default options for every carousels.
    this.defaultOptions
    this.swiper.on('slideChange', function(){
      document.querySelectorAll('.stream-frame')
        .forEach(iframe => iframe.remove());
    });
  }

  // You can set default options in this getter.
  get defaultOptions() {
    return {
      slidesPerView: 1,
      centeredSlides: true,
      //autoplay: {
      //  delay: 5000,
      //  disableOnInteraction: true,
      //},
      loop: true,
      pagination: {
        el: ".swiper-pagination",
          clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    }
  }
}

