let Fraction = require('fraction.js/fraction.js')

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "results", "linesContainer", "lines", "input",
    "totalPercentage", "totalImplied", "decimalLine", "bookmakerMargin" ]

  addLineAndCalculate() {
    const input = this.inputTarget.value
    const inputAsString = input.toString().replace(/\,/, ".")
    let errors = this.validateLine(inputAsString)

    if (errors.length > 0) {
      this.resultsTarget.innerHTML = errors.map(e => `${e}<br/>`)
    } else if (errors.length == 0) {
      const oddsFormat = this.determineOddsFormat(inputAsString)
      const decimalLine = this.translateToDec(inputAsString, oddsFormat)
      const preparedLine =  this.prepareLineData(inputAsString, oddsFormat, decimalLine)
      this.addLine(preparedLine)
      this.recalculateTotal(preparedLine)
      const decimalArray = this.decimalLineTargets.map(x => parseFloat(x.innerHTML))
      this.bookmakerMarginTarget.innerHTML = this.calculateBmMargin(decimalArray)
    }
  }

  recalculateTotal(preparedLine) {
    const previousValue = parseFloat(this.totalPercentageTarget.innerHTML)
    const newValue = parseFloat(preparedLine.percentage)
    let val = 0

    if (isNaN(previousValue)) {
      val = newValue.toFixed(2)
    } else {
      val = (previousValue + newValue).toFixed(2)
    }

    this.totalPercentageTarget.innerHTML = val
    this.totalImpliedTarget.innerHTML = (val/100).toFixed(4)
  }

  clearLines() {
    this.resultsTarget.innerHTML = "Input your odds below and calculate the bookmaker margin!"
    this.linesContainerTarget.replaceChildren()
    this.inputTarget.value = ""
    this.totalPercentageTarget.innerHTML = ""
    this.totalImpliedTarget.innerHTML = ""
    this.bookmakerMarginTarget.innerHTML = ""
  }

  prepareLineData(inputAsString, oddsFormat, decimalLine) {
    return {
      input: `${inputAsString} (${oddsFormat})`,
      percentage: (this.decToImplied(decimalLine) * 100).toFixed(2),
      implied: this.decToImplied(decimalLine),
      decimal: decimalLine,
      fraction: this.decToFraction(decimalLine),
      american: this.decToAmerican(decimalLine),
    }
  }

  addLine(preparedLine) {
    const item = document.createElement("div")
    item.setAttribute("data-title", "aa")
    item.setAttribute(`data-${this.identifier}-target`, "lines")
    item.setAttribute("data-action", `${this.identifier}#removeItem`)
    item.setAttribute("class", "table-row bg-orange-50 justify-between w-full")
    item.innerHTML = `
          <div class=\"table-cell md:pl-3 p-1 bg-orange-100">${preparedLine.input}</div>
          <div class=\"table-cell p-1 text-right\">${preparedLine.percentage}%</div>
          <div class=\"table-cell\ p-1 text-right">${preparedLine.implied}</div>
          <div data-${this.identifier}-target=\"decimalLine\" class=\"table-cell p-1 text-right\">${preparedLine.decimal}</div>
          <div class=\"table-cell p-1 text-right\">${preparedLine.fraction}</div>
          <div class=\"table-cell p-1 text-right md:pr-3">${preparedLine.american}</div>
          `

    this.targets.find("linesContainer").appendChild(item)
  }

  validateLine(inputAsString) {
    let errors = []

    // Check not permitted characters
    const regexOk = inputAsString.match(/^[1-9+-]([0-9.,\/]*)$/g)
    if (regexOk == null) {
      errors.push("Non allowed characters used. Allowed: 0-9 . , / + -")
    }

    // Can't start with 0
    if (inputAsString[0] == 0) {
      errors.push("Odds input can't start with a 0. This applies to decimal, american and fraction odds.")
    }

    // Max one special
    const regexOkSpecials = /[.\/,\/+\-\/\/]/g
    const SpecialCharMatches = inputAsString.match(regexOkSpecials) || 1
    if (1 < SpecialCharMatches.length) {
      errors.push("You can only use one special char (+ - , . /) per odds input.")
    }

    return errors
  }


  determineOddsFormat(line) {
    if (line.indexOf('-') != -1 || line.indexOf('+') != -1) {
      return "american"
    } else if (line.indexOf('/') != -1) {
      return "fraction"
    } else if (line.indexOf('.') != -1 || line.indexOf(',') != -1) {
      return "decimal"
    } else if (parseInt(line) > 100) {
      return "american"
    } else if (parseInt(line) <= 100) {
      return "decimal"
    } else {
      return "unknown"
    }
  }

  translateToDec(line, oddsFormat) {
    switch (oddsFormat) {
      case "american":
        return this.americanToDec(line)
      case "fraction":
        return this.fractionToDec(line)
      case "decimal":
        return line
      default:
        return "N/A"
    }
  }

  calculateBmMargin(decimalArray) {
    let margin = 0.0
    for (let value of decimalArray) { margin += (( 1 / value) * 100) }
    return (margin - 100).toFixed(2)
  }

  fractionToDec(fraction) {
    let f = new Fraction(fraction)
    return (f + 1).toFixed(2)
  }

  americanToDec(american) {
    if (0 < american) {
      return (american / 100 + 1).toFixed(2)
    } else if (american < 0) {
      return (100 / Math.abs(american) + 1).toFixed(2)
    } else {
      alert("Error, american odds cant be 0")
    }
  }

  impliedToDec(implied) {
    return (100 / implied).toFixed(3)
  }

  decToImplied(decimal) {
    return (1 / decimal).toFixed(4)
  }

  decToFraction(decimal) {
    let f = new Fraction(decimal - 1)
    return f.n + "/" + f.d
  }

  decToAmerican(decimal) {
    let american = 0

    if (2 <= decimal) {
      american = (decimal - 1) * 100
    } else {
      american = (-100) / (decimal - 1)
    }

    return american.toFixed(0)
  }
}
