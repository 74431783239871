import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "userLogin", "streamFrame", "rootURL" ]

  loadStream() {
    const html = '<iframe src="https://player.twitch.tv/?channel=' + this.userLoginTarget.value +
      '&parent=' + this.rootURLTarget.value +'&autoplay=true"' +
      ' allowfullscreen=true' +
      ' class="swiper-slide img w-full h-full stream-frame">' +
      '</iframe>'
    let parsed_html = stringToHTML(html)
    this.streamFrameTarget.innerHTML = parsed_html.innerHTML
  }
}

var stringToHTML = function (str) {
  var parser = new DOMParser()
  var doc = parser.parseFromString(str, 'text/html')
  return doc.body
}